import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { Content, Layout } from '../components/common'
import { MetaData } from '../components/common/meta'

/**
* Single post view (/:slug)
*
* This file renders a single post and loads all the content.
*
*/
const PostPage = ({ data, location }) => {
  const post = data.ghostPost

  return (
    <>
      <MetaData
        data={data}
        location={location}
        type="article"
      />
      <Layout>
        <Content content={post} hideDate={post.featured} />
      </Layout>
    </>
  )
}

PostPage.propTypes = {
  data: PropTypes.shape({
    ghostPost: PropTypes.shape({
      title: PropTypes.string.isRequired,
      html: PropTypes.string.isRequired,
      feature_image: PropTypes.string,
      published_at_pretty: PropTypes.string.isRequired
    }).isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}

export default PostPage

export const postQuery = graphql`
    query($slug: String!) {
        ghostPost(slug: { eq: $slug }) {
            ...GhostPostFields
        }
    }
`
